<template>
  <multi-step-form
    :title="formTitle"
    :current.sync="currentStep"
    :steps="formSteps"
  >
    <template #subheader>
      <quick-message
        class="mb-3"
        with-icon
        :show.sync="hasError"
        :message="message"
        type="error"
      />
    </template>
    <template #step-1>
      <client-create-step-one
        :selection-disabled="selectionDisabled"
        @accept="handleAccept"
        @cancel="handleClose"
      />
    </template>
    <template #step-2>
      <client-create-step-two
        :creating="loading"
        @accept="handleAccept"
        @cancel="handleClose"
      />
    </template>
    <template #step-3>
      <client-create-step-three
        :creating="loading"
        @accept="handleAccept"
        @cancel="handleClose"
      />
    </template>
  </multi-step-form>
</template>

<script>
import ClientCreateStepOne from "./ClientCreateStepOne";
import ClientCreateStepTwo from "./ClientCreateStepTwo";
import ClientCreateStepThree from "./ClientCreateStepThree";
import MultiStepForm from "@/app/components/MultiStepForm";
import ClientTypes from "@zubut/common/src/constants/clients/type";

export default {
  name: "ClientCreate",

  components: {
    ClientCreateStepOne,
    ClientCreateStepTwo,
    ClientCreateStepThree,
    MultiStepForm
  },

  props: {
    selectionDisabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data() {
    return {
      currentStep: 1,
      hasError: false,
      message: "",
      loading: false
    };
  },

  computed: {
    clientType() {
      return this.$store.state.clients.create.type;
    },
    formSteps() {
      if (this.clientType === ClientTypes.NUM_BRANCH) {
        return 3;
      }
      return 2;
    },
    formTitle() {
      if (this.clientType === ClientTypes.NUM_CLIENT) {
        return "Crear cliente";
      } else if (this.clientType === ClientTypes.NUM_COMPANY) {
        return "Crear compañía";
      }
      return "Crear sucursal";
    }
  },

  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleAccept() {
      if (
        this.currentStep === 2 &&
        this.clientType !== ClientTypes.NUM_BRANCH
      ) {
        this.loading = true;
        this.$store
          .dispatch("clients/create")
          .then(() => {
            this.reloadClientsListing();
            this.handleClose();
          })
          .finally(() => {
            this.loading = false;
          })
          .catch(err => {
            this.hasError = true;
            this.message =
              err.message ||
              "Ocurrió un error al intentar crear el cliente. Intentalo más tarde.";
          });
      } else if (
        this.currentStep === 3 &&
        this.clientType === ClientTypes.NUM_BRANCH
      ) {
        this.loading = true;
        this.$store
          .dispatch("clients/createBranch")
          .then(res => {
            this.$emit("branch-created", res);
            this.handleClose();
          })
          .catch(err => {
            this.hasError = true;
            this.message =
              err.message ||
              "Ocurrió un error al intentar crear la sucursal. Intentalo más tarde.";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.currentStep += 1;
      }
    },
    reloadClientsListing() {
      this.$store.dispatch("user/getListingUsers", {
        skip: 0,
        limit: 10
      });
    }
  }
};
</script>
