<template>
  <div>
    <b-form-group
      id="fieldset-contact-name"
      label="Nombre del contacto"
      label-for="contact-name"
      class="text-sm"
      description="Gerente, propietario o persona a cargo"
    >
      <b-form-input
        id="contact-name"
        v-model="contactName"
        placeholder="Nombre y apellido"
        trim
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="fieldset-contact-phone"
      label="Teléfono/Celular"
      label-for="contact-phone"
      class="text-sm"
    >
      <vue-tel-input id="contact-phone" v-model="phone" placeholder="--" />
    </b-form-group>
    <b-form-group
      id="fieldset-email"
      label="Correo electrónico"
      label-for="email"
      class="text-sm"
    >
      <b-form-input
        id="email"
        v-model="email"
        placeholder="--"
        trim
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="fieldset-password"
      label="Contraseña"
      label-for="password"
      class="text-sm"
    >
      <b-form-input
        id="password"
        v-model="password"
        placeholder="--"
        trim
        required
      ></b-form-input>
    </b-form-group>
    <div class="mt-5">
      <z-button
        variant="secondary"
        class="mr-3"
        size="lg"
        @click="$emit('cancel')"
      >
        Cancelar
      </z-button>
      <z-button
        type="submit"
        size="lg"
        :disabled="!isValidForm || creating"
        :loading="creating"
        @click="$emit('accept')"
      >
        {{ acceptButtonText }}
      </z-button>
    </div>
  </div>
</template>

<script>
import ClientTypes from "@zubut/common/src/constants/clients/type";
import * as mutation from "@/store/modules/clients/mutations-types";
import { VueTelInput } from "vue-tel-input";
import { validateEmail, validatePhone, validateName } from "@/utils/strings";

export default {
  name: "ClientCreateStepTwo",

  components: {
    VueTelInput
  },

  props: {
    creating: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      vueTel: ""
    };
  },

  computed: {
    clientType() {
      return this.$store.state.clients.create.type;
    },

    contactName: {
      get() {
        return this.$store.state.clients.create.contactName;
      },
      set(val) {
        this.$store.commit(`clients/${mutation.SET_CONTACT_NAME}`, val);
      }
    },

    phone: {
      get() {
        return this.$store.state.clients.create.phone;
      },
      set(val) {
        this.$store.commit(`clients/${mutation.SET_PHONE}`, val);
      }
    },

    email: {
      get() {
        return this.$store.state.clients.create.email;
      },
      set(val) {
        this.$store.commit(`clients/${mutation.SET_EMAIL}`, val);
      }
    },

    password: {
      get() {
        return this.$store.state.clients.create.password;
      },
      set(val) {
        this.$store.commit(`clients/${mutation.SET_PASSWORD}`, val);
      }
    },

    validContactName() {
      return this.contactName.length > 1 && validateName(this.contactName);
    },

    validPhone() {
      return this.phone.length > 1 && validatePhone(this.phone);
    },

    validEmail() {
      return this.email.length > 1 && validateEmail(this.email);
    },

    validPassword() {
      return this.password.length > 1;
    },

    isValidForm() {
      return this.validContactName && this.validEmail && this.validPassword;
    },

    acceptButtonText() {
      if (this.clientType === ClientTypes.NUM_BRANCH) {
        return "Siguiente";
      }
      return "Crear";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/utilities/text-size.scss";
</style>
