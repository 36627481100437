<template>
  <div>
    <b-form-group
      id="fieldset-address"
      label="Dirección"
      label-for="address"
      class="text-sm"
    >
      <address-autocomplete
        class="form-control"
        :address="address"
        placeholder="Calle, No. Int/Ext. Colonia"
        select-first-on-enter
        name="address"
        type="text"
        required
        @address-change="handleAddressChange"
      />
    </b-form-group>
    <b-form-group
      id="fieldset-references"
      label="Referencias"
      label-for="references"
      class="text-sm"
    >
      <b-form-input
        id="references"
        v-model="references"
        placeholder="Cruces, edificios, etc."
        trim
      ></b-form-input>
    </b-form-group>
    <div class="mt-4">
      <z-map
        id="address-map"
        reverse-geocode
        :destinations="mapAddress"
        @decoded-position="handleAddressChange"
      />
    </div>
    <div class="mt-5">
      <z-button
        variant="secondary"
        class="mr-3"
        size="lg"
        @click="$emit('cancel')"
      >
        Cancelar
      </z-button>
      <z-button
        size="lg"
        :disabled="!isValidForm || creating"
        :loading="creating"
        @click="$emit('accept')"
      >
        Crear
      </z-button>
    </div>
  </div>
</template>

<script>
import AddressAutocomplete from "@/app/components/AddressAutocomplete";
import ZMap from "@zubut/common/src/components/ZMap";
import * as mutation from "@/store/modules/clients/mutations-types";

export default {
  name: "ClientCreateStepThree",

  components: {
    AddressAutocomplete,
    ZMap
  },

  props: {
    creating: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    address: {
      get() {
        return this.$store.state.clients.create.address;
      },
      set(val) {
        this.$store.commit(`clients/${mutation.SET_ADDRESS}`, val);
      }
    },
    references: {
      get() {
        return this.$store.state.clients.create.addressReferences;
      },
      set(val) {
        this.$store.commit(`clients/${mutation.SET_ADDRESS_REFERENCES}`, val);
      }
    },
    mapAddress() {
      return this.address.position ? [this.address] : [];
    },
    validAddress() {
      return this.address?.address?.length > 0;
    },
    isValidForm() {
      return this.validAddress;
    }
  },

  methods: {
    handleAddressChange(address) {
      this.address = { ...address };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/utilities/text-size.scss";

#address-map {
  height: 200px;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
}
</style>
