<template>
  <div class="client-create-step-one">
    <b-form-group
      v-if="!selectionDisabled"
      v-slot="{ ariaDescribedby }"
      label="Selecciona el tipo de cliente"
      class="w-100 text-sm"
    >
      <b-form-radio-group
        id="client-type"
        v-model="clientType"
        :options="clientTypeOptions"
        :aria-describedby="ariaDescribedby"
        button-variant="outline-primary"
        name="radio-btn-outline"
        buttons
        class="w-100 text-sm"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group
      v-if="isBranch"
      v-show="!selectionDisabled"
      id="fieldset-parent-company"
      label="Compañía"
      label-for="dropdown-parent-company"
      class="text-sm"
    >
      <z-dropdown-clients
        id="dropdown-parent-company"
        v-model="parentCompany"
        :disabled="selectionDisabled"
        :first-option="false"
        name=""
        :placeholder="
          !parentCompany ? 'Selecciona la compañía a la que pertenece' : ''
        "
        variant="white"
        :client-types="parentCompanyTypes"
      />
    </b-form-group>
    <b-form-group
      id="fieldset-company-name"
      :label="companyNameLabel"
      label-for="company-name"
      class="text-sm"
    >
      <b-form-input
        id="company-name"
        v-model="companyName"
        placeholder="--"
        trim
      ></b-form-input>
    </b-form-group>
    <b-form-group
      v-if="!isBranch"
      id="fieldset-business-line"
      label="Giro de la empresa"
      class="text-sm"
    >
      <z-dropdown
        v-model="businessLine"
        :options="businessLineOptions"
        variant="white"
        :show-header="false"
        expanded
      />
    </b-form-group>
    <div class="mt-5">
      <z-button
        variant="secondary"
        class="mr-3"
        size="lg"
        @click="$emit('cancel')"
      >
        Cancelar
      </z-button>
      <z-button size="lg" :disabled="!isValidForm" @click="$emit('accept')">
        Siguiente
      </z-button>
    </div>
  </div>
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZDropdownClients from "@/app/components/ZDropdownClients";
import ClientTypes from "@zubut/common/src/constants/clients/type";
import BusinessLine from "@/constants/clients/businessLine";
import * as mutation from "@/store/modules/clients/mutations-types";

export default {
  name: "ClientCreateStepOne",

  components: {
    ZDropdown,
    ZDropdownClients
  },

  props: {
    selectionDisabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data() {
    return {
      businessLineOptions: [
        { text: "Selecciona el giro de la empresa", value: null }
      ].concat(BusinessLine.options),
      clientTypeOptions: ClientTypes.options
    };
  },

  computed: {
    businessLine: {
      get() {
        return this.$store.state.clients.create.businessLine;
      },
      set(val) {
        this.$store.commit(`clients/${mutation.SET_BUSINESS_LINE}`, val);
      }
    },
    clientType: {
      get() {
        return this.$store.state.clients.create.type;
      },
      set(val) {
        this.$store.commit(`clients/${mutation.SET_CLIENT_TYPE}`, val);
      }
    },
    companyName: {
      get() {
        return this.$store.state.clients.create.companyName;
      },
      set(val) {
        this.$store.commit(`clients/${mutation.SET_COMPANY_NAME}`, val);
      }
    },
    parentCompany: {
      get() {
        return this.$store.state.clients.create.parentCompany;
      },
      set(val) {
        this.$store.commit(`clients/${mutation.SET_PARENT_COMPANY}`, val);
      }
    },
    isBranch() {
      return this.clientType === ClientTypes.NUM_BRANCH;
    },
    companyNameLabel() {
      if (this.clientType === ClientTypes.NUM_CLIENT) {
        return "Nombre de la empresa";
      } else if (this.clientType === ClientTypes.NUM_COMPANY) {
        return "Nombre de la compañía";
      }
      return "Nombre de la sucursal";
    },
    validBusinessLine() {
      if (
        this.clientType === ClientTypes.NUM_CLIENT ||
        this.clientType === ClientTypes.NUM_COMPANY
      ) {
        return this.businessLine !== null;
      }
      return true;
    },
    validCompanyName() {
      if (this.companyName.length > 1) {
        return true;
      }
      return false;
    },
    validParentCompany() {
      if (this.clientType === ClientTypes.NUM_BRANCH) {
        return this.parentCompany !== null;
      }
      return true;
    },
    isValidForm() {
      return (
        this.validCompanyName &&
        this.validBusinessLine &&
        this.validParentCompany
      );
    },
    parentCompanyTypes() {
      return [ClientTypes.NUM_CLIENT, ClientTypes.NUM_COMPANY];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/utilities/text-size.scss";

.client-create-step-one {
  ::v-deep .btn-group-toggle {
    .btn.btn-outline-primary {
      color: $dark;
      border-color: $zircon;

      &:hover {
        color: $white;
        border-color: $zubut-blue;
      }
    }

    .btn.btn-outline-primary.active {
      color: $white;
      border-color: $zubut-blue;
    }
  }
}
</style>
